import { makeStyles } from '@material-ui/core';
import { color } from '../../../lib/Theme/Theme';

export const useStyles = makeStyles({
  inputLabelStyle: {
    padding: '16px',
    font: 'inherit',
    background: color.darkBlack,
    height: 150,
    border: 'none',
    borderRadius: '6px',
    resize: 'none',
  },
  input: {
    color: color.darkBlack,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    height: 50,
    '@media (max-width: 768px)': {
      marginTop: '20px',
      marginBottom: '10px',
    },
  },
  remainingCharacters: {
    textAlign: 'right',
  },
  label: { margin: '0px 0px' },
  errorMessageStyles: {
    color: color.warning,
    marginTop: 10,
  },

  labelError: {
    color: color.warning,
  },
});

export const loginLabels = {
  color: color.darkBlack,
  fontWeight: 500,
};

export const inputsFields = {
  width: '100%',
  backgroundColor: 'white',
  height: 50,
};

export const inputStylePropsGenerator = (classes: { input: any; labels: any }) => ({
  style: inputsFields,
  labelStyle: loginLabels,
  overrideInputStyles: { input: classes.input },
  overrideLabelStyles: { shrink: classes.labels },
});
