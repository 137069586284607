import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useField } from 'formik';
import {
  AuthorityRent,
  DollarTypeFee,
} from '../../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';

import RenderInput from '../RenderInputField/RenderInputField';
import RenderSelect from '../RenderSelect/RenderSelect';
import { useStyles } from './RenderFeeStyles';

interface RenderFeeProps {
  fieldName: string;
  label: string;
  disabled?: boolean;
  showError?: boolean;
  defaultGst: number;
  defaultPercentFee: number;
  validate?: (value: any) => void;
  defaultCalculatedValue?: string;
  defaultDollerAmount?: number;
  defaultGstAmount?: number;
  defaultFeeType?: string;
  calculatedBasedOnYearRent?: boolean;
}

const RenderFee: React.FC<RenderFeeProps> = ({
  fieldName,
  disabled,
  label,
  defaultGst,
  defaultPercentFee,
  showError,
  validate,
  defaultCalculatedValue,
  defaultDollerAmount,
  defaultGstAmount,
  defaultFeeType,
  calculatedBasedOnYearRent,
}: RenderFeeProps) => {
  const [rent] = useField<AuthorityRent>('rent');
  const [feeField, feeMeta, feeHelpers] = useField<DollarTypeFee>(fieldName);

  const classes = useStyles();

  const calculateDollarAmount = (
    rentAmount: string,
    percentFee: string,
    rentFrequency?: string,
  ): number => {
    if (calculatedBasedOnYearRent && rentFrequency) {
      let annualAmount = rentAmount;
      switch (rentFrequency) {
        case 'Weekly':
          annualAmount = ((parseFloat(rentAmount) * 365) / 7).toFixed(2);
          break;
        case 'Fortnightly':
          annualAmount = ((parseFloat(rentAmount) * 365) / 14).toFixed(2);
          break;
        case 'Monthly':
          annualAmount = (parseFloat(rentAmount) * 12).toFixed(2);
          break;
        default:
          annualAmount = rentAmount;
          break;
      }
      return (parseFloat(annualAmount) * parseFloat(percentFee)) / 100.0;
    }
    return (parseFloat(rentAmount) * parseFloat(percentFee)) / 100.0;
  };

  useEffect(() => {
    if (
      (feeField.value?.feeType === 'fixed' || feeField.value?.feeType === 'calculated') &&
      feeField.value?.dollarAmount
    ) {
      const gst = (parseFloat(feeField.value.dollarAmount) / defaultGst).toFixed(2);
      feeHelpers.setValue({
        ...feeField.value,
        gst: gst.toString(),
      });
    }
  }, [feeField.value?.dollarAmount, feeField.value?.feeType]);

  useEffect(() => {
    if (rent && rent.value && !disabled) {
      const dollarAmount =
        rent.value?.amount && feeField.value?.percentFee
          ? calculateDollarAmount(
              rent.value.amount,
              feeField.value.percentFee,
              rent.value.frequency,
            )
          : feeField.value.dollarAmount
          ? parseFloat(feeField.value.dollarAmount)
          : null;
      const gst = dollarAmount
        ? (dollarAmount / defaultGst).toFixed(2)
        : feeField.value.gst
        ? parseFloat(feeField.value.gst)
        : null;

      feeHelpers.setValue({
        ...feeField.value,
        feeType: feeField.value?.percentFee ? null : feeField.value.feeType,
        rent: feeField.value?.feeType !== 'fixed' ? rent.value.amount || null : null,
        frequency: feeField.value?.feeType !== 'fixed' ? rent.value.frequency || null : null,
        dollarAmount: dollarAmount?.toFixed(2).toString(),
        gst: gst?.toString(),
      });
    }
  }, [rent.value, feeField.value?.percentFee, feeField.value?.feeType]);

  useEffect(() => {
    feeHelpers.setValue({
      ...feeField.value,
      feeType:
        feeField.value && feeField.value.feeType
          ? feeField.value.feeType
          : defaultFeeType && !feeField.value?.percentFee
          ? defaultFeeType
          : null,
      percentFee:
        feeField.value && feeField.value.percentFee
          ? feeField.value.percentFee
          : defaultPercentFee && !feeField.value?.customCalculation
          ? defaultPercentFee.toString()
          : null,
      dollarAmount:
        feeField.value && feeField.value.dollarAmount
          ? feeField.value.dollarAmount
          : defaultDollerAmount
          ? defaultDollerAmount.toString()
          : null,
      gst:
        feeField.value && feeField.value.gst
          ? feeField.value.gst
          : defaultGstAmount
          ? defaultGstAmount.toString()
          : null,
      customCalculation:
        feeField.value && feeField.value.customCalculation
          ? feeField.value.customCalculation
          : defaultCalculatedValue && !feeField.value?.percentFee
          ? defaultCalculatedValue
          : '',
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2" className={classes.subSectionHeader}>
        {label}
      </Typography>
      <Grid container className={classes.feeContainer}>
        <Grid item xs={2} className={classes.feeSubContainer2}>
          <RenderInput name={`${fieldName}.percentFee`} disabled={disabled} />
        </Grid>
        <Grid item xs={10} className={classes.feeSubContainer2}>
          <Typography className={classes.text}>% including GST of the Annual Rental</Typography>
        </Grid>
        <Grid item xs={12} className={classes.feeSubContainer2}>
          <Typography className={classes.orText}>
            <em>OR</em>
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Box marginBottom={2} marginTop={3}>
            <RenderSelect
              label="Select fee type"
              name={`${fieldName}.feeType`}
              disabled={disabled || !!feeField.value?.percentFee}
              options={[
                {
                  value: '',
                  label: 'Select fee type',
                },
                {
                  value: 'calculated',
                  label: 'Calculated',
                },
                {
                  value: 'fixed',
                  label: 'Fixed Fee',
                },
              ]}
            />
          </Box>
        </Grid>

        <Grid item xs={6} className={classes.feeSubContainer2}>
          <Box style={{ width: '100%' }}>
            <RenderInput
              fullWidth
              label="Calculated as follows"
              name={`${fieldName}.customCalculation`}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={`${classes.feeSubContainer2}${classes.orText}`}>
          <Typography>
            <strong>Dollar Amount</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.feeSubContainer}>
          <RenderInput
            name={`${fieldName}.dollarAmount`}
            currencyField
            disabled={disabled}
            validate={validate}
            showError={showError}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.feeSubContainer}>
          <Typography className={classes.text}>including GST of</Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.feeSubContainer}>
          <RenderInput fullWidth name={`${fieldName}.gst`} currencyField disabled={disabled} />
        </Grid>
        {feeField.value?.feeType !== 'fixed' && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.text}>if the Rent is</Typography>
            </Grid>
            <Grid item xs={12} md={5} className={classes.feeSubContainer}>
              <RenderInput fullWidth name={`${fieldName}.rent`} currencyField disabled={disabled} />
            </Grid>
            <Grid item xs={12} md={2} className={classes.feeSubContainer}>
              <Typography className={classes.text}>per</Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <RenderSelect
                label=""
                name={`${fieldName}.frequency`}
                disabled={disabled}
                defaultValue={(rent && rent.value && rent.value.frequency) || ''}
                options={[
                  {
                    value: 'Weekly',
                    label: 'Week',
                  },
                  {
                    value: 'Fortnightly',
                    label: 'Fortnight',
                  },
                  {
                    value: 'Monthly',
                    label: 'Month',
                  },
                  {
                    value: 'Annually',
                    label: 'Annum',
                  },
                ]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default RenderFee;
