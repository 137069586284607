import { ErrorMessage, Field, useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { loginLabels, useStyles } from './RenderEmailStyle';
import { color } from '../../../lib/Theme/Theme';
import errorIcon from '../../../assets/images/error-outline-24-px.svg';

interface RenderEmailInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  hidden?: boolean;
  placeholder?: string;
  showError?: boolean;
  initialValue?: string | number | undefined;
  validate?: (value: any) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  shrink?: boolean;
  fullWidth?: boolean;
  currencyField?: boolean;
}

const emailValidation = (value: string) => {
  if (!value) return 'Email is required';
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return 'Invalid email format';
  }
  if (value.includes(';') || value.includes(',')) {
    return 'Only one email is allowed';
  }
  return undefined;
};

const RenderEmailInput: FC<RenderEmailInputProps> = ({
  name,
  label,
  disabled,
  hidden,
  placeholder,
  showError,
  initialValue,
  currencyField,
  validate,
  onChange,
  shrink,
  fullWidth,
}) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name, validate: validate || emailValidation });
  const [email, setEmail] = useState<string | number | undefined>(field.value);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const showerror = !!meta.error && (meta.touched || showError);

  useEffect(() => {
    setEmail(field.value);
    helpers.setTouched(true);
    helpers.setValue(field.value, true);
  }, [field.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setEmail(value);
    const isValid = !emailValidation(value);
    setIsValidEmail(isValid);
    if (isValid) {
      helpers.setValue(value);
    }
  };

  return (
    <div style={fullWidth ? { width: '100%' } : undefined}>
      <FormControl fullWidth error={showerror}>
        {label && (
          <InputLabel
            style={loginLabels}
            classes={{ shrink: label }}
            htmlFor={name}
            {...(shrink && { shrink })}
          >
            <Typography variant="subtitle1" className={showerror ? classes.labelError : ''}>
              {label}
            </Typography>
          </InputLabel>
        )}
        {!hidden && (
          <Input
            {...field}
            id={name}
            type="email"
            disabled={disabled}
            className={classes.input}
            placeholder={placeholder}
            disableUnderline={false}
            value={email}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <img src={showerror ? errorIcon : ''} alt="" />
              </InputAdornment>
            }
          />
        )}
        {!isValidEmail && email && (
          <FormHelperText className={classes.errorMessageStyles}>Invalid Email</FormHelperText>
        )}
        {name && (
          <FormHelperText className={classes.errorMessageStyles}>
            <ErrorMessage name={name} />
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default RenderEmailInput;
